.Dashboard {
    .Widgets {
        display: flex;

        .Widget {
            &+ .Widget {
                margin-left: 10px;
            }

            flex-basis: 25%;
            border: 1px solid lighten(theme-color("primary"), 25%);
            padding: 15px;
            border-radius: 3px;
            text-align: center;

            border-bottom: 3px solid theme-color("primary");

            a, span {
                font-size: 24px;
            }

            span {
                &+ span {
                    &::before {
                        content: "       |       ";
                        font-size: 10px;
                        vertical-align: middle;
                    }
                }
            }
        }
    }
}
