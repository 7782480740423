@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";

@import "assets/scss/bootstrap";
@import "assets/scss/import";

@import '../node_modules/react-bootstrap-typeahead/css/Typeahead.css';
@import '../node_modules/react-bootstrap-typeahead/css/Typeahead-bs4.css';
@import '../node_modules/react-toastify/dist/ReactToastify.min.css';

html, body, div#root {
    height: 100%;
    width: 100%;
    line-height: 1;
}

body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

button > svg[data-prefix^=fa] {
    margin-left: 5px;
    margin-right: 5px;
}

@import "../node_modules/bootstrap/scss/bootstrap";

button.btn, div.ButtonLink {
    &+ button.btn, &+ div.ButtonLink {
        &.margin-left {
            margin-left: 5px;
        }

        &.margin-right {
            margin-right: 5px;
        }
    }
}
/*
button {
    &.btn {

        &.btn-primary {
            background-color: #ef7b26;

            &:hover {
                background-color: darken(#ef7b26, 10%);
            }

            &:active:focus {
                background-color: darken(#ef7b26, 20%);
            }
        }
    }
}
*/


[class*="CellLayout_main"] {
    [class*="_wrap"] {
        height: 100%;
        width: 100%;
        display: block;

        [class*="_cellContent"] {
            height: 100%;
            padding: 4px;

            &::after {
                content: "";
                height: 100%;
                vertical-align: middle;
                display: inline-block;
                width: 0;
            }
        }
    }
}


.LoadingIndicator {
    display: block;
    text-align: center;

    &[data-size="sm"] {
        height: 16px;
        font-size: 16px;
    }

    &[data-size="md"] {
        height: 24px;
        font-size: 24px;
    }

    &[data-size="lg"] {
        height: 36px;
        font-size: 36px;
    }

    &[data-size="xl"] {
        height: 48px;
        font-size: 48px;
    }
}


h1 {
    font-size: 1.4rem;
}

h2 {
    font-size: 1.3rem;
}

h3 {
    font-size: 1.1rem;
}

.Paginator {
    display: flex;
    align-items: center;
    justify-content: center;
}

.Reload {
    @extend .cursor-pointer;
    display: inline-block;
    margin-left: 10px;
    font-size: 1rem;
}

.hidden {
    display: none;
}

.cursor-pointer {
    cursor: pointer;
}

.NegativeText {
    color: theme-color("negative");
}

.PositiveText {
    color: theme-color("positive");
}


.inline-block {
    display: inline-block;
}

.readonly-normal {
    background: #fff !important;
}

.react-datepicker-wrapper {
    width: 100%;
    display: block;

    input[type="text"] {
        @extend .form-control;
        width: 100%;
    }
}

.colour-picker-cover {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.colour-picker-popover {
    position: absolute;
    z-index: 2;
}

.hold-badge {
    padding: 3px 8px;
    border-radius: 6px;
    vertical-align: middle;
}

h1 .hold-badge {
    font-weight: bold;
    font-size: 1rem;
}

div.Note {
    overflow: hidden;
    position: relative;

    .NoteBadge {
        border: 2px solid rgba(0, 0, 0, 0.05);
        border-radius: 50%;
        float: left;
        width: 46px;
        height: 46px;
        line-height: 44px;
        text-align: center;
        font-weight: bold;
        margin-right: 10px;
        z-index: 2;

        &:after {
            position: absolute;
            width: 2px;
            display: block;
            background: #eee;
            top: 46px;
            bottom: 0;
            left: 22px;
            content: ' ';
            z-index: 1;
        }
    }

    &:nth-last-of-type(2) .NoteBadge:after,
    &:only-of-type .NoteBadge:after {
        display: none;
    }

    .NoteDetail {
        float: left;
        width: calc(100% - 56px);

        .header {
            padding-top: 7px;
            line-height: 20px;

            .datetime {
                font-size: 0.8em;
                color: #888;
            }
        }

        .body {
            padding: 6px 0 30px;
            line-height: 125%;
        }
    }
}

.container-fluid {
    &.no-padding {
        padding-left: 0;
        padding-right: 0;
    }

    .row {
        [class*='col-'] {
            &:not(:first-of-type) {
                border-left: 1px solid grey;
            }

            div.content {
                padding: 5px;
                margin-left: 15px;
                margin-right: 15px;
                margin-bottom: 15px;
            }
        }
    }
}