.Header {
    height: 50px;
    padding-left: 10px;
    border-left-width: $accent-border-left-width;
    border-left-color: $accent-border-left-color;
    border-left-style: $accent-border-left-style;
    background-color: $application-chrome-background-color;

    .Logout {
        @extend .cursor-pointer;
        line-height: 50px;
        margin-right: 10px;

        svg {
            margin-right: 5px;
        }
    }
}
