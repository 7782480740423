$theme-colors: (
    "primary": #ef7b26,

    "negative": #e60000,
    "positive": #2dbd9c,
);

$link-color: theme-color("primary");
$link-hover-color: darken($link-color, 15%);
$enable-rounded:   false;

.ButtonLink {
    display: inline-block;

    button {
        padding: 0;

        &.btn-primary {
            a {
                padding: 0.25rem 0.5rem;
                color: #ffffff;
                display: block;
                width: 100%;
                height: 100%;
            }
        }
    }
}
