.App {
  text-align: left;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App .Body {
    height: 100%;
}

.App > .Body, .App .Main {
    margin-left: 64px;
    width: calc(100vw - 64px);
}

.App.App-sidebar-expanded > .Body, .App.App-sidebar-expanded .Main {
    margin-left: 240px;
    width: calc(100vw - 240px);
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
