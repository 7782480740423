.Main {
    height: calc(100vh - 80px);
    width: 100%;
    padding: 14px;
    overflow-y: auto;

    > div > div,
    .panel {
        background-color: #fff;
        padding: 15px;

        border-bottom: 3px solid theme-color("primary");
    }

    > div > .container-fluid {
        padding-left: 7px;
        padding-right: 7px;
    }

    > div > section > .row > .col {
        padding-left: 7px;
        padding-right: 7px;
    }
}
