$sidebar-item-color: #525252;

$navigation-arrow-width: 12px;
$navigation-arrow-height: 25px;

.Sidebar {
    > nav[class^="sidenav"] {

        top: 50px;
        bottom: 30px;

        border-left-width: $accent-border-left-width;
        border-left-color: $accent-border-left-color;
        border-left-style: $accent-border-left-style;
        background-color: $application-chrome-background-color;

        > button[class^="sidenav"] {
            > span[class^="sidenav"] {
                background-color: theme-color("primary");
            }
        }

        > div[class^="sidenav"][role=menu] {
            div[class^="sidenav"][role=presentation] {

                &[class*="---highlighted---"]:not([class*="---expanded---"]) {
                    position: relative;
                    &:before {
                        position: absolute;

                        box-sizing: border-box;
                        content: "";
                        width: 0;
                        height: 0;
                        border: $navigation-arrow-height solid transparent;
                        border-bottom-width: $navigation-arrow-height;
                        border-top-width: $navigation-arrow-height;

                        border-left-width: $navigation-arrow-width;
                        border-left-style: solid;
                        border-left-color: theme-color("primary");
                    }

                    [class*="---navitem---"]::after {
                        background-color: #eeeceb;
                        opacity: 1;
                    }
                }

                div[class^="sidenav"][role=menuitem] {
                    > div[class^="sidenav"] {
                        color: $sidebar-item-color;

                        > svg {
                            color: theme-color("primary");
                        }
                    }
                }
            }
        }
    }
}
