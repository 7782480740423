@media all and (min-width: 480px) {
    .ForgottenPassword {
        height: 100%;
        width: 100%;

        padding: 60px 0;

        form {
            margin: 0 auto;
            max-width: 320px;
        }

        a {
            display: inline-block;
            width: 100%;
            margin-top: 5px;
            text-align: right;
        }
    }
}
